import React from "react";
import PasswordResetForm from "../components/forms/passwordResetForm";
import LayoutController from "../components/layoutController"

const PasswordReset = () => {
  return (
    <LayoutController language="fi">
        <PasswordResetForm />
     </LayoutController>
  );
}

export default PasswordReset;