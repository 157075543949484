import React, { useState, useContext } from "react"
import { FirebaseContext } from "../../firebase"
import { useMicroCopyFi } from "../../hooks/microCopy"
import GetMicroCopy from "../../shared/getMicroCopy"
import { Link } from "gatsby"
import * as styles from "./form.module.scss"
import { checkValidity } from "../../shared/utility"

const PasswordResetForm = () => {
  const [formValues, setFormValues] = useState({ email: "" })
  const { firebase } = useContext(FirebaseContext)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const microCopyTexts = useMicroCopyFi
  // UI texts

  const labelPasswordLost = GetMicroCopy(
    microCopyTexts,
    "lomakeSalasanaUnohtunut"
  )
  const labelEmail = GetMicroCopy(microCopyTexts, "lomakeSähköposti")
  const labelSubmit = GetMicroCopy(microCopyTexts, "lomakeLähetä")
  const labelErrorEmail = GetMicroCopy(microCopyTexts, "lomakeVirheSähköposti")
  const labelLoginVirtual = GetMicroCopy(
    microCopyTexts,
    "lomakeKirjauduVirtuaalimessut"
  )
  const labelNoEmail = GetMicroCopy(
    microCopyTexts,
    "lomakeSähköpostiEiJärjestelmässä"
  )
  const labelLinkEmail = GetMicroCopy(
    microCopyTexts,
    "lomakeLinkkiSähköpostiin"
  )

  // end UI texts

  function handleSubmit(e) {
    e.preventDefault()
    firebase
      .resetPassword({ email: formValues.email })
      .then(function () {
        setSuccessMessage(labelLinkEmail)
      })
      .catch(error => {
        setErrorMessage(labelNoEmail)
        setSuccessMessage("")
      })
  }

  function handelInputChange(e) {
    e.persist()
    setErrorMessage("")
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))

    switch (e.target.name) {
      case "email":
        if (!checkValidity(e.target.value, { required: true, isEmail: true })) {
          setErrorMessage(labelErrorEmail)
        } else {
          setErrorMessage("")
        }
        break
      default:
        break
    }
  }

  return (
    <section className={styles.form}>
      <div>
        <header>
          <h1>{labelPasswordLost}</h1>
        </header>

        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit}>
            <div className={styles.formRow}>
              <label>
                {labelEmail} <span>*</span>
              </label>
              <input
                value={formValues.email}
                name="email"
                onChange={handelInputChange}
                type="email"
                required
              ></input>
            </div>
            <div className={styles.formRow}>
              {!!errorMessage && (
                <p className={styles.errorMessage}>{errorMessage}</p>
              )}
            </div>
            <button type="submit">{labelSubmit}</button>
          </form>
          {!!successMessage && (
            <div className={styles.formRow}>
              <p className={styles.formNotifications}>{successMessage}</p>
              <p className={styles.formNotifications}>
                <Link to={"/login"}>{labelLoginVirtual}</Link>
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default PasswordResetForm
